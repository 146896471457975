import { Tabs } from "antd"
import React, { useContext } from "react"
import { ListProductsContext } from ".."
import queryString from "query-string"
import { navigate } from "gatsby"
import "../style.css"

const { TabPane } = Tabs

const TabActivePage = () => {
  const { activePage, location, statusCount } = useContext(ListProductsContext)
  const query = queryString.parse(location.search)
  const onChange = (key) => {
    const newQueryStr = queryString.stringify({
      pageSize: 10,
      pageCurrent: 1,
      activePage: key,
    })
    navigate(`/super-listed-product-management/?${newQueryStr}`)
    // switch (key) {
    //   case '1':
    //     setBreakcurmb('登録商品一覧');
    //     break;
    //   case '2':
    //     setBreakcurmb('登録商品一覧 - 出品中');
    //     break;
    //   case '4':
    //     setBreakcurmb('登録商品一覧 - 下書き');
    //     break;
    //   case '5':
    //     setBreakcurmb('登録商品一覧 - 出品停止');
    //     break;

    //   default:
    //     break;
    // }
  }
  return (
    <Tabs
      activeKey={query?.activePage}
      onChange={onChange}
      size="large"
      className="border"
      tabBarStyle={{
        paddingLeft: 20,
      }}
    >
      <TabPane tab="すべて" key="1"></TabPane>
      <TabPane
        tab={
          <span>
            出品中
            <span className="count_border">{statusCount?.countPUBLISHED}</span>
          </span>
        }
        key="2"
      ></TabPane>
      {/* <TabPane
          tab={
            <span>
              申請中
              <span className="count_border">{statusCount?.countPROPOSED}</span>
            </span>
          }
          key="3"
        >
          <Proposed />
        </TabPane> */}
      <TabPane
        tab={
          <span>
            下書き
            <span className="count_border">{statusCount?.countDRAFT}</span>
          </span>
        }
        key="4"
      ></TabPane>
      <TabPane
        tab={
          <span>
            出品停止
            <span className="count_border">{statusCount?.countREJECTED}</span>
          </span>
        }
        key="5"
      ></TabPane>
      {/* <TabPane tab="出品終了" key="5">
          <Proposed />
        </TabPane> */}
    </Tabs>
  )
}

export default TabActivePage
