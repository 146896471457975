import { Breadcrumb, Typography } from "antd"
import { Link, navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import { customTableProduct } from "../../customUtil/global"
import { getSuperProduct } from "../../services/user"
import ListDataTable from "./listDataTable"
import SearchTableListProduct from "./SearchTableListProduct"
import queryString from "query-string"
import { Content } from "antd/lib/layout/layout"

export const ListProductsContext = React.createContext()

function SuperListedProductManagement({
  setBreakcurmb,
  setTitleTag,
  location,
}) {
  const [statusCount, setStatusCount] = useState({})
  const [activePage, setActivePage] = useState("1")
  const [loading, setLoading] = useState(false)

  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [listProductId, setListProductId] = useState([])
  const [paramsSearch, setParamsSearch] = useState(null)
  const [handleSort, setHandleSort] = useState(null)

  const [total, setTotal] = useState(0)
  const [dataProducts, setDataProducts] = useState(null)

  const [tableParams, setTableParams] = useState(null)

  const handleGetListProducts = async () => {
    setLoading(true)
    const query = queryString.parse(location.search)
    const dataSent = {
      offer:
        (tableParams?.pagination?.current - 1) *
        tableParams?.pagination?.pageSize,
      limit: tableParams.pagination.pageSize,
      keyW: query?.keyword ? query?.keyword : null,

      hs_code: query?.inputJAN ? query?.inputJAN : null,
      collectionId: query?.selectCollectionSmall
        ? [query?.selectCollectionSmall]
        : query?.selectCollectionMedium
        ? [query?.selectCollectionMedium]
        : query?.selectCollectionMax
        ? [query?.selectCollectionMax]
        : null,
    }
    if (query?.order && query?.sorterField) {
      switch (query?.order) {
        case "ascend":
          dataSent.orderASC = [query?.sorterField]
          break
        case "descend":
          dataSent.orderDESC = [query?.sorterField]
          break
      }
    }
    if (query?.activePage == "2") {
      dataSent.is_displayed = true
      dataSent.proStatus = ["published"]
    }
    if (query?.activePage == "5") {
      dataSent.is_displayed = false
      dataSent.proStatus = ["published", "rejected"]
    }
    if (query?.activePage == "4") {
      dataSent.proStatus = ["draft"]
    }
    const { response, data } = await getSuperProduct(dataSent)
    console.log("responseresponseresponse", response)
    if (response.status == 200) {
      if (response.status == 200) {
        if (
          response?.data?.data?.count &&
          Math.ceil(response?.data?.data?.count / 10) <
            Number(query?.pageCurrent)
        ) {
          const newQueryStr = queryString.stringify({
            ...query,
            pageCurrent: 1,
          })
          navigate(
            `/super-listed-product-management/?${newQueryStr}`,
            undefined,
            {
              shallow: true,
            }
          )
          return
        }
      }
      const customData = data?.data?.variants?.map((item) =>
        customTableProduct(item)
      )
      setDataProducts(customData)
      setTotal(response?.data?.data?.count)
      setStatusCount({
        ...response.data?.data?.statusCount,
        count: response.data?.data?.count,
      })
      setLoading(false)
    }
  }
  const data = {
    statusCount,
    activePage,
    setActivePage,
    loading,
    setLoading,
    selectedRowKeys,
    setSelectedRowKeys,
    listProductId,
    setListProductId,
    paramsSearch,
    setParamsSearch,
    location,
    setBreakcurmb,
    setTitleTag,
    total,
    setTotal,
    tableParams,
    setTableParams,
    dataProducts,
    setDataProducts,
    handleGetListProducts,
    setHandleSort,
  }

  useEffect(() => {
    if (tableParams) {
      handleGetListProducts()
    }
  }, [tableParams])

  useEffect(() => {
    const query = queryString.parse(location.search)
    if (query?.activePage && ["1", "2", "4", "5"].includes(query?.activePage)) {
      if (Number(query?.pageSize) && Number(query?.pageCurrent)) {
        if (query?.pageSize == "10") {
          setTableParams({
            pagination: {
              current: Number(query?.pageCurrent),
              pageSize: 10,
            },
          })
          return
        }
      } else {
        const newQueryStr = queryString.stringify({
          pageSize: 10,
          pageCurrent: 1,
          activePage: 1,
        })
        navigate(`/super-listed-product-management/?${newQueryStr}`)
      }
    } else {
      console.log("kakakakak")
      const newQueryStr = queryString.stringify({
        pageSize: 10,
        pageCurrent: 1,
        activePage: 1,
      })
      navigate(`/super-listed-product-management/?${newQueryStr}`)
    }
  }, [location])
  return (
    <div>
      <Content>
        <div
          style={{
            backgroundColor: "#FFFFFF",
            padding: "10px",
          }}
        >
          <Breadcrumb separator={">"}>
            <Breadcrumb.Item>
              <Link to="/home">HOME</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>掲載管理</Breadcrumb.Item>
            <Breadcrumb.Item>掲載商品管理</Breadcrumb.Item>
          </Breadcrumb>
          <Typography.Title
            level={2}
            className="!text-2xl !pt-6 !font-normal !leading-[33px]] !tracking-[0.3px]"
          >
            掲載商品管理
          </Typography.Title>
        </div>
        {tableParams && (
          <ListProductsContext.Provider value={data}>
            <SearchTableListProduct />
            <ListDataTable
              setBreakcurmb={setBreakcurmb}
              setTitleTag={setTitleTag}
            />
          </ListProductsContext.Provider>
        )}
      </Content>
    </div>
  )
}

export default SuperListedProductManagement
