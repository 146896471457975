import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons"
import { Button, InputNumber, Spin } from "antd"
import React, { useContext, useState } from "react"
import { removePickUp, setPickUp } from "../../../services/product"
import { ListProductsContext } from ".."

const EditPickUpRank = ({ record }) => {
  const [isEdit, setIsEdit] = useState(false)
  const [indexOfPickUp, setIndexOfPickUp] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const { handleGetListProducts } = useContext(ListProductsContext)
  return (
    <Spin spinning={isLoading}>
      <div className="flex gap-[10px] items-center" name="pick_up">
        <input
          type="checkbox"
          name="pick_up"
          id=""
          style={{
            display: "block",

            cursor: "pointer",
          }}
          onClick={async (e) => {
            if (record?.product?.isPickUp) {
              setIsLoading(true)
              const { response } = await removePickUp({
                productId: record?.product?.id,
              })
              if (response.status == 200) {
                setIsEdit(false)
                setIsLoading(false)
                handleGetListProducts()
              } else {
                setIsLoading(false)
              }
            } else {
              setIsLoading(true)
              const { response } = await setPickUp({
                productId: record?.product?.id,
              })
              if (response.status == 200) {
                setIsEdit(false)
                setIsLoading(false)
                handleGetListProducts()
              } else {
                setIsLoading(false)
              }
            }
          }}
          checked={record?.product?.isPickUp}
        />
        <InputNumber
          name="pick_up"
          disabled={!isEdit}
          value={indexOfPickUp || record?.product?.indexPickUp}
          onChange={(e) => {
            console.log("eeee", e)

            setIndexOfPickUp(e)
          }}
          onBlur={(e) => {
            console.log("eeee22", e.target?.value)

            setIndexOfPickUp(e.target?.value)
          }}
        />
        {record?.product?.isPickUp && (
          <div>
            {" "}
            {isEdit ? (
              <div className="flex gap-[6px]">
                <CheckOutlined
                  style={{ fontSize: "17px" }}
                  onClick={async () => {
                    setIsLoading(true)
                    const { response } = await setPickUp({
                      productId: record?.product?.id,
                      index:
                        Number(indexOfPickUp) !== 0
                          ? Number(indexOfPickUp)
                          : null,
                    })
                    if (response.status == 200) {
                      setIsEdit(false)
                      setIsLoading(false)
                      handleGetListProducts()
                    } else {
                      setIsLoading(false)
                    }
                  }}
                />
                <CloseOutlined
                  style={{ fontSize: "20px" }}
                  onClick={() => {
                    setIsEdit(false)
                    setIndexOfPickUp(null)
                  }}
                />
              </div>
            ) : (
              <EditOutlined
                style={{ fontSize: "20px" }}
                onClick={() => {
                  setIsEdit(true)
                }}
              />
            )}
          </div>
        )}
      </div>
    </Spin>
  )
}

export default EditPickUpRank
