import React from "react"
import TabActivePage from "./TabActivePage"
import "../style.css"
import HeaderRow from "./HeaderRow"
import DataTable from "./DataTable"

const ListDataTable = () => {
  return (
    <div className="mt-4">
      <TabActivePage />
      <HeaderRow />
      <DataTable />
    </div>
  )
}

export default ListDataTable
