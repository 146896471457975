import { Spin } from "antd"
import React, { useContext, useState } from "react"
import { hidenVariant } from "../../../services/product"
import { ListProductsContext } from ".."

const HidenPrice = ({ record }) => {
  const [isLoading, setIsLoading] = useState(false)
  const { handleGetListProducts } = useContext(ListProductsContext)
  return (
    <Spin spinning={isLoading}>
      <div
        className="flex gap-[10px] justify-center items-center"
        name="pick_up"
      >
        <input
          type="checkbox"
          name="pick_up"
          id=""
          style={{
            display: "block",

            cursor: "pointer",
          }}
          onClick={async (e) => {
            setIsLoading(true)
            const { response } = await hidenVariant({
              variantIds: [record?.id],
              status: !record?.hidenPrices,
            })
            if (response.status == 200) {
              setIsLoading(false)
              handleGetListProducts()
            } else {
              setIsLoading(false)
            }
          }}
          checked={record?.hidenPrices}
        />
      </div>
    </Spin>
  )
}

export default HidenPrice
