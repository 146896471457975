import React, { useContext, useEffect, useState } from "react"
import {
  Col,
  Form,
  Row,
  Typography,
  Input,
  Button,
  Divider,
  Pagination,
  Space,
  Select,
  Empty,
  Spin,
} from "antd"
import "./style.scss"

import { itemRender } from "../../customUtil/table"
import { ListProductsContext } from "."
import queryString from "query-string"
import { navigate } from "gatsby"
import { AccountContext } from "../../context/account"
export default function SearchTableListProduct() {
  const { collections } = useContext(AccountContext)

  const [collectionsMax, setCollectionsMax] = useState()
  const [collectionsMedium, setCollectionsMedium] = useState()
  const [collectionsSmall, setCollectionsSmall] = useState()
  const { setParamsSearch, total, tableParams, location, loading } = useContext(
    ListProductsContext
  )
  const [form] = Form.useForm()

  const onFinish = (values) => {
    const query = queryString.parse(location.search)

    console.log(values)
    const newQueryStr = {
      ...query,
      ...values,
      ...{
        pageSize: 10,
        pageCurrent: 1,
      },
    }

    navigate(
      `/super-listed-product-management/?${queryString.stringify(newQueryStr)}`,
      undefined,
      {
        shallow: false,
      }
    )
  }

  const onPagenationChange = (page) => {
    const query = queryString.parse(location.search)
    const newQueryStr = {
      ...query,
      pageCurrent: page,
    }
    navigate(
      `/super-listed-product-management/?${queryString.stringify(newQueryStr)}`,
      undefined,
      {
        shallow: false,
      }
    )
  }

  useEffect(() => {
    setCollectionsMax(collections)
  }, [collections])

  const handleCollectionMax = (value) => {
    form.setFieldsValue({ selectCollectionMedium: undefined })
    form.setFieldsValue({ selectCollectionSmall: undefined })
    setCollectionsSmall([])
    const data = collectionsMax.find((col) => col.id == value)
    setCollectionsMedium(data ? data.children : "")
  }

  const onSecondCityChange = (value) => {
    form.setFieldsValue({ selectCollectionSmall: undefined })
    const data = collectionsMedium.find((col) => col.id == value)
    setCollectionsSmall(data ? data.children : "")
  }

  useEffect(() => {
    if (collectionsMax) {
      const query = queryString.parse(location.search)

      if (query?.selectCollectionMax) {
        const data = collectionsMax.find(
          (col) => col.id == query?.selectCollectionMax
        )
        setCollectionsMedium(data ? data.children : "")
      }
      if (query?.selectCollectionMedium && query?.selectCollectionMax) {
        const data = collectionsMax.find(
          (col) => col.id == query?.selectCollectionMax
        )
        const data2 = data?.children?.find(
          (col) => col.id == query?.selectCollectionMedium
        )
        setCollectionsSmall(data2 ? data2.children : "")
      }
      form.setFieldsValue({
        inputJAN: query?.inputJAN,
        selectCollectionSmall: query?.selectCollectionSmall,
        selectCollectionMax: query?.selectCollectionMax,
        selectCollectionMedium: query?.selectCollectionMedium,
        keyword: query?.keyword,
      })
    }
  }, [location, collectionsMax])
  return (
    <div className="customFormSearch">
      <Form
        colon={false}
        onFinish={onFinish}
        style={{ backgroundColor: "#FAFAFA", padding: "2rem" }}
        labelCol={{ span: 4 }}
        form={form}
        requiredMark={false}
        labelAlign="left"
        wrapperCol={{ span: 17 }}
      >
        <Row className="mb-3">
          <Typography style={{ color: "#9B9B9B" }} className="font-medium">
            絞り込み条件
          </Typography>
        </Row>
        <Spin spinning={collections?.length == 0}>
          {" "}
          <Row>
            <Col span={12}>
              {collections?.length > 0 && (
                <Form.Item
                  label={
                    <Typography
                      style={{ display: "flex", width: "85px" }}
                      className="font-semibold mt-1"
                    >
                      カテゴリ
                    </Typography>
                  }
                >
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      width: "100%",
                      gap: 10,
                    }}
                  >
                    <Form.Item
                      name="selectCollectionMax"
                      className=" flex flex-1  [&_.ant-form-item-row]:w-full"
                    >
                      <Select
                        className="select_search-select w-full"
                        placeholder="大カテゴリ"
                        showSearch
                        allowClear
                        onChange={handleCollectionMax}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      >
                        {collectionsMax &&
                          collectionsMax.map((great) => (
                            <Select.Option value={great.id} key={great.id}>
                              {great.title}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="selectCollectionMedium"
                      className=" flex flex-1 [&_.ant-form-item-row]:w-full"
                    >
                      <Select
                        className=" select_search-select"
                        onChange={onSecondCityChange}
                        placeholder="中カテゴリ"
                        showSearch
                        allowClear
                        optionFilterProp="children"
                        notFoundContent={
                          <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={<span>データなし</span>}
                          />
                        }
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      >
                        {collectionsMedium &&
                          collectionsMedium.map((git) => (
                            <Select.Option value={git.id} key={git.id}>
                              {git.title}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="selectCollectionSmall"
                      className=" flex flex-1 [&_.ant-form-item-row]:w-full"
                    >
                      <Select
                        className="select_search-select"
                        placeholder="小カテゴリ"
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        notFoundContent={
                          <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={<span>データなし</span>}
                          />
                        }
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      >
                        {collectionsSmall &&
                          collectionsSmall.map((small) => (
                            <Select.Option value={small.id} key={small.id}>
                              {small.title}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </div>
                </Form.Item>
              )}
            </Col>
            <Col span={12}>
              <Form.Item
                name="inputJAN"
                allowClear
                label={
                  <Typography
                    className="font-semibold"
                    style={{ display: "flex", width: "93px" }}
                  >
                    自社品番JAN
                  </Typography>
                }
              >
                <Input placeholder="JANで探す" />
              </Form.Item>
            </Col>
          </Row>
        </Spin>
        <Divider></Divider>

        <Row>
          <Col span={12}>
            <Form.Item
              name="keyword"
              allowClear
              label={
                <Typography
                  className="font-semibold"
                  style={{ display: "flex" }}
                >
                  キーワード
                </Typography>
              }
            >
              <Input
                placeholder="キーワードで探す"
                allowClear
                style={{ width: "70%" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="justify-center pt-8">
          <Button
            htmlType="submit"
            className="w-36 ml-[10px]"
            type="primary"
            loading={loading}
          >
            検索
          </Button>
        </Row>
      </Form>
      <Row className="mt-[46px] flex justify-end">
        <Spin spinning={loading}>
          <Pagination
            current={tableParams.pagination.current}
            total={total}
            itemRender={itemRender}
            showSizeChanger={false}
            showTotal={(total, range) => (
              <div>
                {range[0]}~{range[1]} 件 表示 &nbsp;&nbsp;&nbsp; (全
                {total}件)
              </div>
            )}
            onChange={onPagenationChange}
          />
        </Spin>
      </Row>
    </div>
  )
}
