import { Button, Col, Modal, Row, Select, Space } from "antd"
import React, { useContext, useState } from "react"
import { ProfileOutlined } from "@ant-design/icons"
import { Link } from "gatsby"
import { deleteVariant, updateVariant } from "../../../services/apiProduct"
import { openCustomNotificationWithIcon } from "../../../common/notifycation"
import { ListProductsContext } from ".."
import { AccountContext } from "../../../context/account"
import queryString from "query-string"
import { navigate } from "gatsby"
const HeaderRow = () => {
  const { user } = useContext(AccountContext)
  const {
    selectedRowKeys,
    listProductId,
    setSelectedRowKeys,
    handleGetListProducts,
    location,
  } = useContext(ListProductsContext)
  const [show, setShow] = useState(null)
  const [modal2Visible, setModal2Visible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleClick = () => {
    if (!show) {
      return
    }
    if (show === "公開") {
      updateVariant({ id: selectedRowKeys, is_displayed: true })
        .then((response) => {
          openCustomNotificationWithIcon(
            "success",
            "正常に更新されました。",
            ""
          )
          handleGetListProducts()
        })
        .catch((err) => {
          openCustomNotificationWithIcon("error", "更新に失敗しました。", "")

          console.log(err)
        })
    } else if (show === "非公開") {
      updateVariant({ id: selectedRowKeys, is_displayed: false })
        .then((response) => {
          openCustomNotificationWithIcon(
            "success",
            "正常に更新されました。",
            ""
          )
          handleGetListProducts()
        })
        .catch((err) => {
          openCustomNotificationWithIcon("error", "更新に失敗しました。", "")
          console.log(err)
        })
    } else {
      setModal2Visible(true)
    }
  }
  const query = queryString.parse(location.search)

  const handleSort = (value) => {
    if (value) {
      value = JSON.parse(
        value == "1"
          ? `{ "order": "ascend", "field": "hs_code" }`
          : value == "2"
          ? `{ "order": "descend", "field": "hs_code" }`
          : value == "3"
          ? `{ "order": "ascend", "field": "created_at" }`
          : value == "4"
          ? `{ "order": "descend", "field": "created_at" }`
          : value == "5"
          ? `{ "order": "ascend", "field": "retail" }`
          : value == "6"
          ? `{ "order": "descend", "field": "retail" }`
          : value == "7"
          ? `{ "order": "descend", "field": "updated_at" }`
          : value == "8"
          ? `{ "order": "descend", "field": "updated_at" }`
          : ""
      )
      const newQueryStr = {
        ...query,
      }
      delete newQueryStr.sorterField
      delete newQueryStr.order
      if (value.order) {
        newQueryStr.sorterField = value.field
        newQueryStr.order = value.order
      }
      navigate(
        `/super-listed-product-management/?${queryString.stringify(
          newQueryStr
        )}`,
        undefined,
        {
          shallow: false,
        }
      )
      return
    } else {
      const newQueryStr = {
        ...query,
      }
      delete newQueryStr.sorterField
      delete newQueryStr.order
      navigate(
        `/super-listed-product-management/?${queryString.stringify(
          newQueryStr
        )}`,
        undefined,
        {
          shallow: false,
        }
      )
    }
  }
  return (
    <div>
      <Row className="bg-[#f3f3f3] p-4">
        <Col span={8}>
          <Space direction="vertical">
            <Space style={{ paddingBottom: 10 }}>
              <div className="text-[#333] " style={{ whiteSpace: "nowrap" }}>
                {"一括操作"}
              </div>
              <Select
                allowClear
                placeholder={"操作を選択"}
                style={{ width: 180 }}
                value={show}
                onChange={(e) => {
                  setShow(e)
                }}
              >
                <Select.Option value="削除">削除</Select.Option>
              </Select>
              <Button
                style={{
                  padding: "0 0.6rem",
                }}
                type="primary"
                danger
                disabled={selectedRowKeys?.length == 0}
                loading={isLoading}
                onClick={handleClick}
              >
                実施
              </Button>
              <div className="pl-4">
                <Select
                  placeholder="並び替え"
                  className="w-36 "
                  allowClear
                  value={
                    (query?.order == "ascend" &&
                      query?.sorterField == "hs_code" &&
                      "1") ||
                    (query?.order == "descend" &&
                      query?.sorterField == "hs_code" &&
                      "2") ||
                    (query?.order == "ascend" &&
                      query?.sorterField == "created_at" &&
                      "3") ||
                    (query?.order == "descend" &&
                      query?.sorterField == "created_at" &&
                      "4") ||
                    (query?.order == "ascend" &&
                      query?.sorterField == "retail" &&
                      "5") ||
                    (query?.order == "descend" &&
                      query?.sorterField == "retail" &&
                      "6") ||
                    (query?.order == "ascend" &&
                      query?.sorterField == "updated_at" &&
                      "7") ||
                    (query?.order == "descend" &&
                      query?.sorterField == "updated_at" &&
                      "8") ||
                    null
                  }
                  onChange={handleSort}
                >
                  <Select.Option value={`1`}>JANコード 昇順</Select.Option>
                  <Select.Option value={`2`}>JANコード 降順</Select.Option>
                  <Select.Option value={`3`}>登録日 昇順</Select.Option>
                  <Select.Option value={`4`}>登録日 降順</Select.Option>
                  <Select.Option value={`7`}>更新日 昇順</Select.Option>
                  <Select.Option value={`8`}> 更新日 降順</Select.Option>
                  <Select.Option value={`5`}>販売価格 昇順</Select.Option>
                  <Select.Option value={`6`}>販売価格 降順</Select.Option>
                </Select>
              </div>
            </Space>
          </Space>
        </Col>
        {/* <Col span={6} offset={9}>
          <Space>
            <Link to="./new-product">
              <Button
                disabled={user?.role === "member"}
                style={{
                  backgroundColor: "#ECB427",
                  color: "white",
                  width: "10rem",
                }}
              >
                ＋ 新規商品登録
              </Button>
            </Link>

            <Button type="default">
              <Space>
                <ProfileOutlined
                  style={{ verticalAlign: "middle", fontSize: "1rem" }}
                />
                商品CSVダウンロード
              </Space>
            </Button>
          </Space>
        </Col> */}
      </Row>
      <Modal
        title="この商品を削 除してもよろしいでしょうか？"
        okText="削除"
        cancelText="キャンセル"
        centered
        visible={modal2Visible}
        onOk={async () => {
          setModal2Visible(false)
          setIsLoading(true)
          const { response } = await deleteVariant({
            productId: listProductId,
            variantId: selectedRowKeys,
          })
          if (response.status == 200) {
            handleGetListProducts()
            setSelectedRowKeys([])
            setShow(null)
            setIsLoading(false)
            openCustomNotificationWithIcon(
              "success",
              "正常に更新されました。",
              ""
            )
          } else {
            setIsLoading(false)
            openCustomNotificationWithIcon("error", "更新に失敗しました。", "")
          }
        }}
        onCancel={() => setModal2Visible(false)}
      >
        <p>
          このアクションを実施すると受注に関する商品に影響を与える可能性があります。
        </p>
      </Modal>
    </div>
  )
}

export default HeaderRow
